import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import BestSeller_Card from './Components/BestSeller_Card'
import BrandRanking_Card from './Components/BrandRanking_Card'
import SupplierRanking_Card from './Components/SupplierRanking_Card'
import SubCateSale_Card from './Components/SubCateSale_Card'
import StatusBadge from './Components/StatusBadge'
import kFormatter, { thousandsSeparator } from 'Utils/kFormatter'
import { ReactComponent as Redirect } from 'Assets/images/redirect.svg'
import { Popover } from 'antd'
export const ComputeTypeColumns = (type?: string) => {
  switch (type) {
    case 'best_seller':
      return BEST_SELLERS_COLUMNS
    case 'brand_ranking':
      return BRAND_RANKING_COLUMNS
    case 'supplier_ranking':
      return SUPPLIER_COLUMNS
    case 'sub_cate_sale':
      return SUBCATE_SALE_COLUMNS
    case 'product_sale_by_sale_channel':
      return PRODUCT_SALE_BY_SALE_CHANNEL_COLUMNS
    case 'product_sale_by_delivery_method':
      return PRODUCT_SALE_BY_DELIVERY_METHOD_COLUMNS
    case 'subcate_sale_by_sale_channel':
      return SUBCATE_SALE_BY_SALE_CHANNEL_COLUMNS
    case 'subcate_sale_by_delivery_method':
      return SUBCATE_SALE_BY_DELIVERY_METHOD_COLUMNS
    case 'inventory_by_store':
      return INVENTORY_BY_STORE_COLUMNS
    case 'inventory_by_province':
      return INVENTORY_BY_PROVINCE_COLUMNS
  }
}

export const ComputeTypeCard = (type?: string, data?: any) => {
  switch (type) {
    case 'best_seller':
      return <BestSeller_Card data={data}></BestSeller_Card>
    case 'brand_ranking':
      return <BrandRanking_Card data={data}></BrandRanking_Card>
    case 'supplier_ranking':
      return <SupplierRanking_Card data={data}></SupplierRanking_Card>
    case 'sub_cate_sale':
      return <SubCateSale_Card data={data}></SubCateSale_Card>
  }
}
export interface BestSellerDataType {
  key: number
  page_view?: number
  ranking: number
  product_name: string
  product_performance: string
  inventory_status: string
  category_role: string
  image: string
  sale_channel: string
  supplier: string
  sold_item: number
  gross_margin: number
  no_of_new_customers: number
  no_of_returned_customers: number
  revenue: number
  total_rev: number
  drr: number
  inventory: number
  inventory_days: number
  cost_price: number
  list_price: number
  promotion: string[]
  unit: string
  url?: string
}

export const BEST_SELLERS_COLUMNS: ColumnsType<BestSellerDataType> = [
  {
    title: 'Ranking',
    dataIndex: 'ranking',
    key: 'ranking',
    width: 96,
    fixed: 'left',
    sorter: {
      compare: (a, b) => a.ranking - b.ranking,
      multiple: 1,
    },
  },
  {
    title: 'Product Name',
    dataIndex: 'product_name',
    key: 'product_name',
    width: 220,
    fixed: 'left',
    sorter: {
      compare: (a, b) => a.product_name.localeCompare(b.product_name),
      multiple: 2,
    },
    render: (text: string, data) => (
      <span className="flex flex-row justify-between Col-hover-url cursor-pointer">
        <div>{text}</div>
        <div>
          {' '}
          <Redirect onClick={() => window.open(data?.url, '_blank')}></Redirect>
        </div>
      </span>
    ),
  },
  {
    title: 'Product Performance',
    dataIndex: 'product_performance',
    key: 'product_performance',
    width: 156,
    render: (text: string) => (
      <div>
        <StatusBadge status={text}></StatusBadge>
      </div>
    ),
  },
  {
    title: 'Inventory Status',
    dataIndex: 'inventory_status',
    key: 'inventory_status',
    width: 132,
    render: (text: string) => (
      <div>
        <StatusBadge status={text}></StatusBadge>
      </div>
    ),
  },
  // {
  //   title: 'Category Role',
  //   dataIndex: 'category_role',
  //   key: 'category_role',
  //   width: 126,
  // },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    width: 68,
    render: (text: string) => (
      <div>
        <img src={text} alt="" width={'100%'} height={'100%'} />
      </div>
    ),
  },
  {
    title: 'Sale Channel',
    dataIndex: 'sale_channel',
    key: 'sale_channel',
    width: 230,
  },
  {
    title: 'Supplier',
    dataIndex: 'supplier',
    key: 'supplier',
    width: 230,
  },
  {
    title: 'Sold Item',
    dataIndex: 'sold_item',
    key: 'sold_item',
    width: 124,
    sorter: {
      compare: (a, b) => a.sold_item - b.sold_item,
      multiple: 3,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'Gross Margin',
    dataIndex: 'gross_margin',
    key: 'gross_margin',
    width: 132,
    sorter: {
      compare: (a, b) => a.gross_margin - b.gross_margin,
      multiple: 4,
    },
    render: (value: number) => <div>{value?.toFixed(1)}%</div>,
  },
  {
    title: 'No. of New Customer',
    dataIndex: 'no_of_new_customers',
    key: 'no_of_new_customers',
    width: 158,
    sorter: {
      compare: (a, b) => a.no_of_new_customers - b.no_of_new_customers,
      multiple: 5,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'No. of Return Customer',
    dataIndex: 'no_of_returned_customers',
    key: 'no_of_returned_customers',
    width: 206,
    sorter: {
      compare: (a, b) => a.no_of_returned_customers - b.no_of_returned_customers,
      multiple: 6,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'Revenue',
    dataIndex: 'revenue',
    key: 'revenue',
    width: 124,
    sorter: {
      compare: (a, b) => a.revenue - b.revenue,
      multiple: 7,
    },
    render: (value: number) => <div>{kFormatter(value)}</div>,
  },
  {
    title: '% Total Rev',
    dataIndex: 'total_rev',
    key: 'total_rev',
    width: 115,
    sorter: {
      compare: (a, b) => a.total_rev - b.total_rev,
      multiple: 8,
    },
    render: (value: number) => <div>{value?.toFixed(2)}%</div>,
  },
  {
    title: 'DRR',
    dataIndex: 'drr',
    key: 'drr',
    width: 74,
    sorter: {
      compare: (a, b) => a.drr - b.drr,
      multiple: 9,
    },
    render: (value: number) => <div>{value?.toFixed(0)}</div>,
  },
  {
    title: 'Inventory Quantity',
    dataIndex: 'inventory',
    key: 'inventory',
    width: 132,
    sorter: {
      compare: (a, b) => a.inventory - b.inventory,
      multiple: 10,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'Inventory Days',
    dataIndex: 'inventory_days',
    key: 'inventory_days',
    width: 132,
    sorter: {
      compare: (a, b) => a.inventory_days - b.inventory_days,
      multiple: 11,
    },
    render: (value: number) => <div>{value?.toFixed(0)}</div>,
  },
  {
    title: 'Cost Price',
    dataIndex: 'cost_price',
    key: 'cost_price',
    width: 106,
    sorter: {
      compare: (a, b) => a.cost_price - b.cost_price,
      multiple: 12,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
  },
  {
    title: 'List Price',
    dataIndex: 'list_price',
    key: 'list_price',
    width: 106,
    sorter: {
      compare: (a, b) => a.list_price - b.list_price,
      multiple: 13,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
  },
  {
    title: 'Promotion for customer',
    dataIndex: 'promotion',
    key: 'promotion',
    width: 230,
    render: (value: string[]) => (
      <div>
        {value?.length <= 1 ? (
          value?.map((item, index) => {
            return (
              <div>
                <span>
                  <span>{item}</span>
                </span>
              </div>
            )
          })
        ) : (
          <div>
            <span>
              <span>{value[0]}</span>
            </span>
            <Popover
              content={
                <span className="overflow-scroll" style={{ maxHeight: 552 }}>
                  {value?.map((item, index) => {
                    if (index >= 1) return <div>{item}</div>
                  })}
                </span>
              }
            >
              <span className="ml-1" style={{ color: '#1890FF' }}>{` + ${value?.length - 1
                } other promotions`}</span>
            </Popover>
          </div>
        )}
      </div>
    ),
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    width: 100,
  },
]

export interface BrandRankingDataType {
  ranking: number
  brand_name: string
  sales: number
  brand_performance: string
  no_of_purchased_order: number
  purchased_value: number
  revenue: number
  gross_profit: number
  ratio_gross_profit: number
  new_customer: number
  return_customer: number
  revenue_per_customer: number
  inventory_days: number
  inventory_value: number
  storage: string[]
  total_of_sku: number
  total_of_product_performance: number
  new: number
  red: number
  green: number
  yellow: number
  star: number
  clear: number
  total_of_category_role: number
  traffic: number
  profit: number
  image: number
  total_of_sale_channel: number
  web: number
  app: number
  kingfoodmart: number
  kingfoodmart_mini: number
  no_of_sku_sale_channel?: any
  no_of_sku_category_role?: any
  no_of_sku_product_performance?: any
  no_of_sku?: any
}
export const BRAND_RANKING_COLUMNS: ColumnsType<BrandRankingDataType> = [
  {
    title: 'Ranking',
    dataIndex: 'ranking',
    key: 'ranking',
    width: 96,
    fixed: 'left',
    sorter: {
      compare: (a, b) => a.ranking - b.ranking,
      multiple: 1,
    },
  },
  {
    title: 'Brand Name',
    dataIndex: 'brand_name',
    key: 'brand_name',
    width: 160,
    fixed: 'left',
    sorter: {
      compare: (a, b) => a.brand_name.localeCompare(b.brand_name),
      multiple: 2,
    },
  },
  {
    title: 'Brand Performance',
    dataIndex: 'brand_performance',
    key: 'brand_performance',
    width: 140,
    render: (status: string) => (
      <div>
        <StatusBadge status={status}></StatusBadge>
      </div>
    ),
  },
  {
    title: 'No. of Purchase Order',
    dataIndex: 'no_of_purchased_order',
    key: 'no_of_purchased_order',
    width: 185,
    sorter: {
      compare: (a, b) => a.no_of_purchased_order - b.no_of_purchased_order,
      multiple: 3,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'Purchased Value',
    dataIndex: 'purchased_value',
    key: 'purchased_value',
    width: 150,
    sorter: {
      compare: (a, b) => a.purchased_value - b.purchased_value,
      multiple: 4,
    },
    render: (value: number) => <div>{kFormatter(value)}</div>,
  },
  {
    title: 'Revenue',
    dataIndex: 'revenue',
    key: 'revenue',
    width: 140,
    sorter: {
      compare: (a, b) => a.revenue - b.revenue,
      multiple: 5,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
  },
  {
    title: 'Gross Profit',
    dataIndex: 'gross_profit',
    key: 'gross_profit',
    width: 140,
    sorter: {
      compare: (a, b) => a.gross_profit - b.gross_profit,
      multiple: 6,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
  },
  {
    title: '% Gross Profit',
    dataIndex: 'ratio_gross_profit',
    key: 'ratio_gross_profit',
    width: 132,
    sorter: {
      compare: (a, b) => a.ratio_gross_profit - b.ratio_gross_profit,
      multiple: 7,
    },
    render: (value: number) => <div>{value?.toFixed(1)}%</div>,
  },
  {
    title: 'No. of New Customer',
    dataIndex: 'new_customer',
    key: 'new_customer',
    width: 132,
    sorter: {
      compare: (a, b) => a.new_customer - b.new_customer,
      multiple: 8,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'No. of Return Customer',
    dataIndex: 'return_customer',
    key: 'return_customer',
    width: 144,
    sorter: {
      compare: (a, b) => a.return_customer - b.return_customer,
      multiple: 9,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'Revenue/Customer',
    dataIndex: 'revenue_per_customer',
    key: 'revenue_per_customer',
    width: 162,
    sorter: {
      compare: (a, b) => a.revenue_per_customer - b.revenue_per_customer,
      multiple: 10,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
  },
  {
    title: 'Inventory Days',
    dataIndex: 'inventory_days',
    key: 'inventory_days',
    width: 138,
    sorter: {
      compare: (a, b) => a.inventory_days - b.inventory_days,
      multiple: 11,
    },
    render: (value: number) => <div>{value?.toFixed(0)}</div>,
  },
  {
    title: 'Inventory Value',
    dataIndex: 'inventory_value',
    key: 'inventory_value',
    width: 142,
    sorter: {
      compare: (a, b) => a.inventory_value - b.inventory_value,
      multiple: 12,
    },
    render: (value: number) => <div>{kFormatter(value)}</div>,
  },
  {
    title: 'No. of SKUs',
    dataIndex: 'no_of_sku',
    key: 'no_of_sku',
    width: 328,
    children: [
      {
        title: 'Storage',
        dataIndex: 'storage',
        key: 'storage',
        width: 230,
        render: (value: string[]) => (
          <div>
            {value?.length <= 1 ? (
              value?.map((item, index) => {
                return (
                  <div>
                    <span>
                      <span>{item}</span>
                    </span>
                  </div>
                )
              })
            ) : (
              <div>
                <span>
                  <span>{value[0]}</span>
                </span>
                <Popover
                  content={
                    <span className="overflow-scroll" style={{ maxHeight: 552 }}>
                      {value?.map((item, index) => {
                        if (index >= 1) return <div>{item}</div>
                      })}
                    </span>
                  }
                >
                  <span className="ml-1" style={{ color: '#1890FF' }}>{` + ${value?.length - 1
                    } other stores`}</span>
                </Popover>
              </div>
            )}
          </div>
        ),
      },
      {
        title: 'Total',
        dataIndex: 'total_of_sku',
        key: 'total_of_sku',
        width: 104,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
    ],
  },
  {
    title: 'No. of SKUs (Product Performance)',
    dataIndex: 'no_of_sku_product_performance',
    key: 'no_of_sku_product_performance',
    width: 504,
    children: [
      {
        title: 'Total',
        dataIndex: 'total_of_product_performance',
        key: 'total_of_product_performance',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'New',
        dataIndex: 'new',
        key: 'new',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Red',
        dataIndex: 'red',
        key: 'red',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Green',
        dataIndex: 'green',
        key: 'green',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Yellow',
        dataIndex: 'yellow',
        key: 'yellow',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Star',
        dataIndex: 'star',
        key: 'star',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Clear',
        dataIndex: 'clear',
        key: 'clear',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
    ],
  },
  {
    title: 'No. of SKUs (Category Role)',
    dataIndex: 'no_of_sku_category_role',
    key: 'no_of_sku_category_role',
    width: 288,
    children: [
      {
        title: 'Total',
        dataIndex: 'total_of_category_role',
        key: 'total_of_category_role',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Traffic',
        dataIndex: 'traffic',
        key: 'traffic',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      // {
      //   title: 'Profit',
      //   dataIndex: 'profit',
      //   key: 'profit',
      //   width: 72,
      //   render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      // },
      // {
      //   title: 'Image',
      //   dataIndex: 'image',
      //   key: 'image',
      //   width: 72,
      //   render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      // },
    ],
  },
  {
    title: 'No. of SKUs (Sale Channel)',
    dataIndex: 'no_of_sku_sale_channel',
    key: 'no_of_sku_sale_channel',
    width: 452,
    children: [
      {
        title: 'Total',
        dataIndex: 'total_of_sale_channel',
        key: 'total_of_sale_channel',
        width: 64,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Web',
        dataIndex: 'web',
        key: 'web',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'App',
        dataIndex: 'app',
        key: 'app',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Kingfood Mart',
        dataIndex: 'kingfoodmart',
        key: 'kingfoodmart',
        width: 112,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Kingfood Mart Mini',
        dataIndex: 'kingfoodmart_mini',
        key: 'kingfoodmart_mini',
        width: 132,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
    ],
  },
]

export interface SupplierDataType {
  ranking: number
  sales: number
  supplier_name: string
  supplier_performance: string
  purchased_value: number
  no_of_purchased_order: number
  revenue: number
  gross_profit: number
  ratio_gross_profit: number
  new_customer: number
  return_customer: number
  revenue_per_customer: number
  inventory_days: number
  inventory_value: number
  credit_days: number
  credit_value: number
  account_payable: number
  storage: string[]
  no_of_sku?: any
  total_of_sku: number
  new: number
  red: number
  green: number
  yellow: number
  star: number
  clear: number
}

export const SUPPLIER_COLUMNS: ColumnsType<SupplierDataType> = [
  {
    title: 'Ranking',
    dataIndex: 'ranking',
    key: 'ranking',
    width: 96,
    fixed: 'left',
    sorter: {
      compare: (a, b) => a.ranking - b.ranking,
      multiple: 1,
    },
  },
  {
    title: 'Supplier Name',
    dataIndex: 'supplier_name',
    key: 'supplier_name',
    width: 229,
    fixed: 'left',
    sorter: {
      compare: (a, b) => a.supplier_name.localeCompare(b.supplier_name),
      multiple: 2,
    },
  },
  {
    title: 'Supplier Performance',
    dataIndex: 'supplier_performance',
    key: 'supplier_performance',
    width: 159,
    render: (status: string) => (
      <div>
        <StatusBadge status={status}></StatusBadge>
      </div>
    ),
  },
  {
    title: 'Purchased Value',
    dataIndex: 'purchased_value',
    key: 'purchased_value',
    width: 150,
    sorter: {
      compare: (a, b) => a.purchased_value - b.purchased_value,
      multiple: 3,
    },
    render: (value: number) => <div>{kFormatter(value)}</div>,
  },
  {
    title: 'No. of Purchase Order',
    dataIndex: 'no_of_purchased_order',
    key: 'no_of_purchased_order',
    width: 186,
    sorter: {
      compare: (a, b) => a.no_of_purchased_order - b.no_of_purchased_order,
      multiple: 4,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'Revenue',
    dataIndex: 'revenue',
    key: 'revenue',
    width: 124,
    sorter: {
      compare: (a, b) => a.revenue - b.revenue,
      multiple: 5,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
  },
  {
    title: 'Gross Profit',
    dataIndex: 'gross_profit',
    key: 'gross_profit',
    width: 140,
    sorter: {
      compare: (a, b) => a.gross_profit - b.gross_profit,
      multiple: 6,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
  },
  {
    title: '% Gross Profit',
    dataIndex: 'ratio_gross_profit',
    key: 'ratio_gross_profit',
    width: 132,
    sorter: {
      compare: (a, b) => a.ratio_gross_profit - b.ratio_gross_profit,
      multiple: 7,
    },
    render: (value: number) => <div>{value.toFixed(1)}%</div>,
  },
  {
    title: 'No. of New Customer',
    dataIndex: 'new_customer',
    key: 'new_customer',
    width: 132,
    sorter: {
      compare: (a, b) => a.new_customer - b.new_customer,
      multiple: 8,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'No. of Return Customer',
    dataIndex: 'return_customer',
    key: 'return_customer',
    width: 144,
    sorter: {
      compare: (a, b) => a.return_customer - b.return_customer,
      multiple: 9,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'Revenue/Customer',
    dataIndex: 'revenue_per_customer',
    key: 'revenue_per_customer',
    width: 170,
    sorter: {
      compare: (a, b) => a.revenue_per_customer - b.revenue_per_customer,
      multiple: 10,
    },
    render: (value: number) => <div>{kFormatter(value)}</div>,
  },
  {
    title: 'Inventory Days',
    dataIndex: 'inventory_days',
    key: 'inventory_days',
    width: 142,
    sorter: {
      compare: (a, b) => a.inventory_days - b.inventory_days,
      multiple: 11,
    },
    render: (value: number) => <div>{value?.toFixed(0)}</div>,
  },
  {
    title: 'Inventory Value',
    dataIndex: 'inventory_value',
    key: 'inventory_value',
    width: 142,
    sorter: {
      compare: (a, b) => a.inventory_value - b.inventory_value,
      multiple: 12,
    },
    render: (value: number) => <div>{kFormatter(value)}</div>,
  },
  {
    title: 'Credit Days',
    dataIndex: 'credit_days',
    key: 'credit_days',
    width: 124,
    sorter: {
      compare: (a, b) => a.credit_days - b.credit_days,
      multiple: 13,
    },
    render: (value: number) => <div>{value?.toFixed(0)}</div>,
  },
  {
    title: 'Credit Value',
    dataIndex: 'credit_value',
    key: 'credit_value',
    width: 150,
    sorter: {
      compare: (a, b) => a.credit_value - b.credit_value,
      multiple: 14,
    },
    render: (value: number) => <div>{kFormatter(value)}</div>,
  },
  {
    title: 'Account Payable',
    dataIndex: 'account_payable',
    key: 'account_payable',
    width: 150,
    sorter: {
      compare: (a, b) => a.account_payable - b.account_payable,
      multiple: 15,
    },
    render: (value: number) => <div>{kFormatter(value)}</div>,
  },
  {
    title: 'Storage',
    dataIndex: 'storage',
    key: 'storage',
    width: 250,
    render: (value: string[]) => (
      <div>
        {value?.length <= 1 ? (
          value?.map((item, index) => {
            return (
              <div>
                <span>
                  <span>{item}</span>
                </span>
              </div>
            )
          })
        ) : (
          <div>
            <span>
              <span>{value[0]}</span>
            </span>
            <Popover
              content={
                <span className="overflow-scroll" style={{ maxHeight: 552 }}>
                  {value?.map((item, index) => {
                    if (index >= 1) return <div>{item}</div>
                  })}
                </span>
              }
            >
              <span className="ml-1" style={{ color: '#1890FF' }}>{` + ${value?.length - 1
                } other stores`}</span>
            </Popover>
          </div>
        )}
      </div>
    ),
  },
  {
    title: 'No. of SKU',
    dataIndex: 'no_of_sku',
    key: 'no_of_sku',
    width: 504,
    children: [
      {
        title: 'Total',
        dataIndex: 'total_of_sku',
        key: 'total_of_sku',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'New',
        dataIndex: 'new',
        key: 'new',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Red',
        dataIndex: 'red',
        key: 'red',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Green',
        dataIndex: 'green',
        key: 'green',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Yellow',
        dataIndex: 'yellow',
        key: 'yellow',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Star',
        dataIndex: 'star',
        key: 'star',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Clear',
        dataIndex: 'clear',
        key: 'clear',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
    ],
  },
]

export interface SubCateSaleDataType {
  ranking: number
  sub_categories_name: string
  inventory_status: string
  revenue: number
  gross_profit: number
  ratio_gross_profit: number
  new_customer: number
  return_customer: number
  revenue_per_customer: number
  inventory_days: number
  inventory_value: number
  daily_sales: number
  sale_channel: string
  no_of_sku?: any
  total: number
  new: number
  red: number
  green: number
  yellow: number
  star: number
  clear: number
}

export const SUBCATE_SALE_COLUMNS: ColumnsType<SubCateSaleDataType> = [
  {
    title: 'Ranking',
    dataIndex: 'ranking',
    key: 'ranking',
    width: 96,
    fixed: 'left',
    sorter: {
      compare: (a, b) => a.ranking - b.ranking,
      multiple: 1,
    },
  },
  {
    title: 'Sub-Categories Name',
    dataIndex: 'sub_categories_name',
    key: 'sub_categories_name',
    width: 229,
    fixed: 'left',
    sorter: {
      compare: (a, b) => a.sub_categories_name.localeCompare(b.sub_categories_name),
      multiple: 2,
    },
  },
  {
    title: 'Inventory Status',
    dataIndex: 'inventory_status',
    key: 'inventory_status',
    width: 164,
    render: (status: string) => (
      <div>
        <StatusBadge status={status}></StatusBadge>
      </div>
    ),
  },
  {
    title: 'Revenue',
    dataIndex: 'revenue',
    key: 'revenue',
    width: 124,
    sorter: {
      compare: (a, b) => a.revenue - b.revenue,
      multiple: 5,
    },
    render: (value: number) => <div>{kFormatter(value)}</div>,
  },
  {
    title: 'Gross Profit',
    dataIndex: 'gross_profit',
    key: 'gross_profit',
    width: 124,
    sorter: {
      compare: (a, b) => a.gross_profit - b.gross_profit,
      multiple: 6,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
  },
  {
    title: '% Gross Profit',
    dataIndex: 'ratio_gross_profit',
    key: 'ratio_gross_profit',
    width: 132,
    sorter: {
      compare: (a, b) => a.ratio_gross_profit - b.ratio_gross_profit,
      multiple: 7,
    },
    render: (value: number) => <div>{value?.toFixed(1)}%</div>,
  },
  {
    title: 'No. of New Customer',
    dataIndex: 'new_customer',
    key: 'new_customer',
    width: 132,
    sorter: {
      compare: (a, b) => a.new_customer - b.new_customer,
      multiple: 8,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'No. of Return Customer',
    dataIndex: 'return_customer',
    key: 'return_customer',
    width: 144,
    sorter: {
      compare: (a, b) => a.return_customer - b.return_customer,
      multiple: 9,
    },
    render: (value: number) => <div>{thousandsSeparator(value)}</div>,
  },
  {
    title: 'Revenue/Customer',
    dataIndex: 'revenue_per_customer',
    key: 'revenue_per_customer',
    width: 170,
    sorter: {
      compare: (a, b) => a.revenue_per_customer - b.revenue_per_customer,
      multiple: 10,
    },
    render: (value: number) => <div>{kFormatter(value)}</div>,
  },
  {
    title: 'Inventory Days',
    dataIndex: 'inventory_days',
    key: 'inventory_days',
    width: 142,
    sorter: {
      compare: (a, b) => a.inventory_days - b.inventory_days,
      multiple: 11,
    },
    render: (value: number) => <div>{value.toFixed(0)}</div>,
  },
  {
    title: 'Inventory Value',
    dataIndex: 'inventory_value',
    key: 'inventory_value',
    width: 142,
    sorter: {
      compare: (a, b) => a.inventory_value - b.inventory_value,
      multiple: 12,
    },
    render: (value: number) => <div>{kFormatter(value)}</div>,
  },
  {
    title: 'Daily Sales',
    dataIndex: 'daily_sales',
    key: 'daily_sales',
    width: 124,
    sorter: {
      compare: (a, b) => a.daily_sales - b.daily_sales,
      multiple: 13,
    },
    render: (value: number) => <div>{kFormatter(value)}</div>,
  },
  {
    title: 'Sale Channel',
    dataIndex: 'sale_channel',
    key: 'sale_channel',
    width: 252,
  },
  {
    title: 'No. of SKU',
    dataIndex: 'no_of_sku',
    key: 'no_of_sku',
    width: 504,
    children: [
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'New',
        dataIndex: 'new',
        key: 'new',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Red',
        dataIndex: 'red',
        key: 'red',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Green',
        dataIndex: 'green',
        key: 'green',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Yellow',
        dataIndex: 'yellow',
        key: 'yellow',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Star',
        dataIndex: 'star',
        key: 'star',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Clear',
        dataIndex: 'clear',
        key: 'clear',
        width: 72,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
    ],
  },
]

export interface ProductSaleBySaleChannelDataType {
  revenue?: any
  product_name: string
  total: number
  web: number
  app: number
  kingfood_mini: number
  kingfood_mart: number
}

export const PRODUCT_SALE_BY_SALE_CHANNEL_COLUMNS: ColumnsType<ProductSaleBySaleChannelDataType> = [
  {
    title: 'Product Name',
    dataIndex: 'product_name',
    key: ' product_name',
    width: 232,
    fixed: 'left',
    sorter: {
      compare: (a, b) => a.product_name.localeCompare(b.product_name),
      multiple: 1,
    },
  },
  {
    title: 'Revenue',
    dataIndex: 'revenue',
    key: 'revenue',
    children: [
      {
        title: 'Total',
        dataIndex: 'sum_revenue_total',
        key: 'sum_revenue_total',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
      },
      {
        title: 'Kingfood Mart',
        dataIndex: 'sum_revenue_kingfood_mart',
        key: 'sum_revenue_kingfood_mart',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
      },
      {
        title: 'Kingfood Mini',
        dataIndex: 'sum_revenue_kingfood_mini',
        key: 'sum_revenue_kingfood_mini',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
      },
      {
        title: 'Web',
        dataIndex: 'sum_revenue_web',
        key: 'sum_revenue_web',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
      },
      {
        title: 'App',
        dataIndex: 'sum_revenue_app',
        key: 'sum_revenue_app',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
      },
    ],
  },
  {
    title: 'Quantity',
    dataIndex: 'sum_quantity',
    key: 'sum_quantity',
    children: [
      {
        title: 'Total',
        dataIndex: 'sum_quantity_total',
        key: 'sum_quantity_total',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Kingfood Mart',
        dataIndex: 'sum_quantity_kingfood_mart',
        key: 'sum_quantity_kingfood_mart',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Kingfood Mini',
        dataIndex: 'sum_quantity_kingfood_mini',
        key: 'sum_quantity_kingfood_mini',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Web',
        dataIndex: 'sum_quantity_web',
        key: 'sum_quantity_web',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'App',
        dataIndex: 'sum_quantity_app',
        key: 'sum_quantity_app',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
    ],
  },
  {
    title: 'No. of Customer',
    dataIndex: 'no_of_customer',
    key: 'no_of_customer',
    children: [
      {
        title: 'Total',
        dataIndex: 'no_of_customer_total',
        key: 'no_of_customer_total',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Kingfood Mart',
        dataIndex: 'no_of_customer_kingfood_mart',
        key: 'no_of_customer_kingfood_mart',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Kingfood Mini',
        dataIndex: 'no_of_customer_kingfood_mini',
        key: 'no_of_customer_kingfood_mini',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Web',
        dataIndex: 'no_of_customer_web',
        key: 'no_of_customer_web',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'App',
        dataIndex: 'no_of_customer_app',
        key: 'no_of_customer_app',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
    ],
  },
  {
    title: 'Profit',
    dataIndex: 'profit',
    key: 'profit',
    children: [
      {
        title: 'Total',
        dataIndex: 'sum_profit_total',
        key: 'sum_profit_total',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
      },
      {
        title: 'Kingfood Mart',
        dataIndex: 'sum_profit_kingfood_mart',
        key: 'sum_profit_kingfood_mart',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
      },
      {
        title: 'Kingfood Mini',
        dataIndex: 'sum_profit_kingfood_mini',
        key: 'sum_profit_kingfood_mini',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
      },
      {
        title: 'Web',
        dataIndex: 'sum_profit_web',
        key: 'sum_profit_web',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
      },
      {
        title: 'App',
        dataIndex: 'sum_profit_app',
        key: 'sum_profit_app',
        width: 148,
        render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
      },
    ],
  },

  // {
  //   title: 'Total Revenue',
  //   dataIndex: 'total_revenue',
  //   key: 'total_revenue',
  //   children: [
  //     {
  //       title: 'Total',
  //       dataIndex: 'sum_total_revenue_total',
  //       key: 'sum_total_revenue_total',
  //       width: 148,
  //     },
  //     {
  //       title: 'Web',
  //       dataIndex: 'sum_total_revenue_web',
  //       key: 'sum_total_revenue_web',
  //       width: 148,
  //     },
  //     {
  //       title: 'App',
  //       dataIndex: 'sum_total_revenue_app',
  //       key: 'sum_total_revenue_app',
  //       width: 148,
  //     },
  //     {
  //       title: 'Kingfood Mini',
  //       dataIndex: 'sum_total_revenue_kingfood_mini',
  //       key: 'sum_total_revenue_kingfood_mini',
  //       width: 148,
  //     },
  //     {
  //       title: 'Kingfood Mart',
  //       dataIndex: 'sum_total_revenue_kingfood_mart',
  //       key: 'sum_total_revenue_kingfood_mart',
  //       width: 148,
  //     },
  //   ],
  // },
]

export interface ProductSaleByDeliveryMethodDataType {
  revenue?: any
  product_name: string
  total: number
  instore: number
  pickup: number
  delivery: number
}

export const PRODUCT_SALE_BY_DELIVERY_METHOD_COLUMNS: ColumnsType<ProductSaleByDeliveryMethodDataType> =
  [
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
      width: 232,
      fixed: 'left',
      sorter: {
        compare: (a, b) => a.product_name.localeCompare(b.product_name),
        multiple: 1,
      },
    },
    {
      title: 'Revenue',
      dataIndex: 'revenue',
      key: 'revenue',
      children: [
        {
          title: 'Total',
          dataIndex: 'sum_revenue_total',
          key: 'sum_revenue_total',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
        },
        {
          title: 'Instore',
          dataIndex: 'sum_revenue_instore',
          key: 'sum_revenue_instore',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
        },
        {
          title: 'Delivery',
          dataIndex: 'sum_revenue_delivery',
          key: 'sum_revenue_delivery',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
        },
        {
          title: 'Pickup',
          dataIndex: 'sum_revenue_pickup',
          key: 'sum_revenue_pickup',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
        },
        {
          title: 'Home Delivery',
          dataIndex: 'sum_revenue_home_delivery',
          key: 'sum_revenue_home_delivery',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
        },
      ],
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      children: [
        {
          title: 'Total',
          dataIndex: 'sum_quantity_total',
          key: 'sum_quantity_total',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}</div>,
        },
        {
          title: 'Instore',
          dataIndex: 'sum_quantity_instore',
          key: 'sum_quantity_instore',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}</div>,
        },
        {
          title: 'Delivery',
          dataIndex: 'sum_quantity_delivery',
          key: 'sum_quantity_delivery',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}</div>,
        },
        {
          title: 'Pickup',
          dataIndex: 'sum_quantity_pickup',
          key: 'sum_quantity_pickup',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}</div>,
        },
        {
          title: 'Home Delivery',
          dataIndex: 'sum_quantity_home_delivery',
          key: 'sum_quantity_home_delivery',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}</div>,
        },
      ],
    },
    // {
    //   title: 'Total Revenue',
    //   dataIndex: 'total_revenue',
    //   key: 'total_revenue',
    //   children: [
    //     {
    //       title: 'Total',
    //       dataIndex: 'sum_total_revenue_total',
    //       key: 'sum_total_revenue_total',
    //       width: 184,
    //     },
    //     {
    //       title: 'Instore',
    //       dataIndex: 'sum_total_revenue_instore',
    //       key: 'sum_total_revenue_instore',
    //       width: 184,
    //     },
    //     {
    //       title: 'Pickup',
    //       dataIndex: 'sum_total_revenue_pickup',
    //       key: 'sum_total_revenue_pickup',
    //       width: 184,
    //     },
    //     {
    //       title: 'Delivery',
    //       dataIndex: 'sum_total_revenue_delivery',
    //       key: 'sum_total_revenue_delivery',
    //       width: 184,
    //     },
    //   ],
    // },
  ]

export interface SubcateSaleBySalesChannelDataType {
  revenue?: any
  sub_category: string
  total: number
  web: number
  app: number
  kingfood_mini: number
  kingfood_mart: number
}

export const SUBCATE_SALE_BY_SALE_CHANNEL_COLUMNS: ColumnsType<SubcateSaleBySalesChannelDataType> =
  [
    {
      title: 'Sub-Categories',
      dataIndex: 'sub_category',
      key: 'sub_category',
      width: 232,
      fixed: 'left',
      sorter: {
        compare: (a, b) => a.sub_category.localeCompare(b.sub_category),
        multiple: 1,
      },
    },
    {
      title: 'Revenue',
      dataIndex: 'revenue',
      key: 'revenue',
      children: [
        {
          title: 'Total',
          dataIndex: 'sum_revenue_total',
          key: 'sum_revenue_total',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
        },
        {
          title: 'Kingfood Mart',
          dataIndex: 'sum_revenue_kingfood_mart',
          key: 'sum_revenue_kingfood_mart',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
        },
        {
          title: 'Kingfood Mini',
          dataIndex: 'sum_revenue_kingfood_mini',
          key: 'sum_revenue_kingfood_mini',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
        },
        {
          title: 'Web',
          dataIndex: 'sum_revenue_web',
          key: 'sum_revenue_web',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
        },
        {
          title: 'App',
          dataIndex: 'sum_revenue_app',
          key: 'sum_revenue_app',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
        },
      ],
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      children: [
        {
          title: 'Total',
          dataIndex: 'sum_quantity_total',
          key: 'sum_quantity_total',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}</div>,
        },
        {
          title: 'Kingfood Mart',
          dataIndex: 'sum_quantity_kingfood_mart',
          key: 'sum_quantity_kingfood_mart',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}</div>,
        },
        {
          title: 'Kingfood Mini',
          dataIndex: 'sum_quantity_kingfood_mini',
          key: 'sum_quantity_kingfood_mini',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}</div>,
        },
        {
          title: 'Web',
          dataIndex: 'sum_quantity_web',
          key: 'sum_quantity_web',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}</div>,
        },
        {
          title: 'App',
          dataIndex: 'sum_quantity_app',
          key: 'sum_quantity_app',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}</div>,
        },
      ],
    },
    // {
    //   title: 'Total Revenue',
    //   dataIndex: 'total_revenue',
    //   key: 'total_revenue',
    //   children: [
    //     {
    //       title: 'Total',
    //       dataIndex: 'sum_total_revenue_total',
    //       key: 'sum_total_revenue_total',
    //       width: 148,
    //     },
    //     {
    //       title: 'Web',
    //       dataIndex: 'sum_total_revenue_web',
    //       key: 'sum_total_revenue_web',
    //       width: 148,
    //     },
    //     {
    //       title: 'App',
    //       dataIndex: 'sum_total_revenue_app',
    //       key: 'sum_total_revenue_app',
    //       width: 148,
    //     },
    //     {
    //       title: 'Kingfood Mini',
    //       dataIndex: 'sum_total_revenue_kingfood_mini',
    //       key: 'sum_total_revenue_kingfood_mini',
    //       width: 148,
    //     },
    //     {
    //       title: 'Kingfood Mart',
    //       dataIndex: 'sum_total_revenue_kingfood_mart',
    //       key: 'sum_total_revenue_kingfood_mart',
    //       width: 148,
    //     },
    //   ],
    // },
  ]

export interface SubcateSaleByDeliveryMethodDataType {
  revenue?: any
  sub_category: string
  total: number
  instore: number
  pickup: number
  delivery: number
}

export const SUBCATE_SALE_BY_DELIVERY_METHOD_COLUMNS: ColumnsType<SubcateSaleByDeliveryMethodDataType> =
  [
    {
      title: 'Sub-Categories',
      dataIndex: 'sub_category',
      key: 'sub_category',
      width: 232,
      fixed: 'left',
      sorter: {
        compare: (a, b) => a.sub_category.localeCompare(b.sub_category),
        multiple: 1,
      },
    },
    {
      title: 'Revenue',
      dataIndex: 'revenue',
      key: 'revenue',
      children: [
        {
          title: 'Total',
          dataIndex: 'sum_revenue_total',
          key: 'sum_revenue_total',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
        },
        {
          title: 'Instore',
          dataIndex: 'sum_revenue_instore',
          key: 'sum_revenue_instore',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
        },
        {
          title: 'Delivery',
          dataIndex: 'sum_revenue_delivery',
          key: 'sum_revenue_delivery',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
        },
        {
          title: 'Pickup',
          dataIndex: 'sum_revenue_pickup',
          key: 'sum_revenue_pickup',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
        },
        {
          title: 'Home Delivery',
          dataIndex: 'sum_revenue_home_delivery',
          key: 'sum_revenue_home_delivery',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}đ</div>,
        },
      ],
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      children: [
        {
          title: 'Total',
          dataIndex: 'sum_quantity_total',
          key: 'sum_quantity_total',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}</div>,
        },
        {
          title: 'Instore',
          dataIndex: 'sum_quantity_instore',
          key: 'sum_quantity_instore',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}</div>,
        },
        {
          title: 'Delivery',
          dataIndex: 'sum_quantity_delivery',
          key: 'sum_quantity_delivery',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}</div>,
        },
        {
          title: 'Pickup',
          dataIndex: 'sum_quantity_pickup',
          key: 'sum_quantity_pickup',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}</div>,
        },
        {
          title: 'Home Delivery',
          dataIndex: 'sum_quantity_home_delivery',
          key: 'sum_quantity_home_delivery',
          width: 148,
          render: (value: number) => <div>{thousandsSeparator(value)}</div>,
        },
      ],
    },
    // {
    //   title: 'Total Revenue',
    //   dataIndex: 'total_revenue',
    //   key: 'total_revenue',
    //   children: [
    //     {
    //       title: 'Total',
    //       dataIndex: 'sum_total_revenue_total',
    //       key: 'sum_total_revenue_total',
    //       width: 184,
    //     },
    //     {
    //       title: 'Instore',
    //       dataIndex: 'sum_total_revenue_instore',
    //       key: 'sum_total_revenue_instore',
    //       width: 184,
    //     },
    //     {
    //       title: 'Pickup',
    //       dataIndex: 'sum_total_revenue_pickup',
    //       key: 'sum_total_revenue_pickup',
    //       width: 184,
    //     },
    //     {
    //       title: 'Delivery',
    //       dataIndex: 'sum_total_revenue_delivery',
    //       key: 'sum_total_revenue_delivery',
    //       width: 184,
    //     },
    //   ],
    // },
  ]

export interface InventoryByStoreDataType {
  sku?: string
  image?: string
  storage?: string[]
  metrics?: any
  inventory_status: string
  inventory_quantity: number
  inventory_value: number
  inventory_day: number
  avg_revenue_per_day: number
  inventory_quantity_at_dc: number
  inventory_quantity_at_store: number
  inventory_day_at_store: number
  no_of_new_customer: number
  no_of_return_customer: number
  no_of_bad_inventory: number
}

export const INVENTORY_BY_STORE_COLUMNS: ColumnsType<InventoryByStoreDataType> = [
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    width: 140,
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    width: 68,
    render: (image: string) => <img src={image} width={'100%'} height={'100%'} />,
  },
  {
    title: 'Storage',
    dataIndex: 'storage',
    key: 'storage',
    width: 264,
    render: (value: string[]) => (
      <div>
        {value?.length <= 1 ? (
          value?.map((item, index) => {
            return (
              <div>
                <span>
                  <span>{item}</span>
                </span>
              </div>
            )
          })
        ) : (
          <div>
            <span>
              <span>{value[0]}</span>
            </span>
            <Popover
              content={
                <span className="overflow-scroll" style={{ maxHeight: 552 }}>
                  {value?.map((item, index) => {
                    if (index >= 1) return <div>{item}</div>
                  })}
                </span>
              }
            >
              <span className="ml-1" style={{ color: '#1890FF' }}>{` + ${value?.length - 1
                } other stores`}</span>
            </Popover>
          </div>
        )}
      </div>
    ),
  },
  {
    title: 'Metrics',
    dataIndex: 'metrics',
    key: 'metrics',
    children: [
      {
        title: 'Inventory Status',
        dataIndex: 'inventory_status',
        key: 'inventory_status',
        width: 128,
        render: (status: string) => (
          <div>
            <StatusBadge status={status}></StatusBadge>
          </div>
        ),
      },
      {
        title: 'Inventory Quantity',
        dataIndex: 'inventory_quantity',
        key: 'inventory_quantity',
        width: 140,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Inventory Value',
        dataIndex: 'inventory_value',
        key: 'inventory_value',
        width: 123,
        render: (value: number) => <div>{kFormatter(value)}</div>,
      },
      {
        title: 'Inventory Day',
        dataIndex: 'inventory_day',
        key: 'inventory_day',
        width: 113,
        render: (value: number) => <div>{value?.toFixed(0)}</div>,
      },
      {
        title: 'Avg Revenue/day (last 7 days)',
        dataIndex: 'avg_revenue_per_day',
        key: 'avg_revenue_per_day',
        width: 202,
        render: (value: number) => <div>{kFormatter(value)}</div>,
      },
      {
        title: 'Inventory Quantity at DC',
        dataIndex: 'inventory_quantity_at_dc',
        key: 'inventory_quantity_at_dc',
        width: 172,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Inventory Day at DC',
        dataIndex: 'inventory_day_at_dc',
        key: 'inventory_day_at_dc',
        width: 148,
        render: (value: number) => <div>{value?.toFixed(0)}</div>,
      },
      {
        title: 'Inventory Quantity at Store',
        dataIndex: 'inventory_quantity_at_store',
        key: 'inventory_quantity_at_store',
        width: 189,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Inventory Day at Store',
        dataIndex: 'inventory_day_at_store',
        key: 'inventory_day_at_store',
        width: 161,
        render: (value: number) => <div>{value?.toFixed(0)}</div>,
      },
      {
        title: 'No. of New Customer',
        dataIndex: 'no_of_new_customer',
        key: 'no_of_new_customer',
        width: 156,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'No. of Return Customer',
        dataIndex: 'no_of_return_customer',
        key: 'no_of_return_customer',
        width: 169,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'No. of Bad Inventory',
        dataIndex: 'no_of_bad_inventory',
        key: 'no_of_bad_inventory',
        width: 169,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
    ],
  },
]

export interface InventoryByProvinceDataType {
  sku: string
  image: string
  storage: string
  metrics?: any
  total: number
  inventory_status: string
  inventory_quantity: number
  inventory_value: number
  inventory_day: number
  avg_revenue_per_day: number
  inventory_quantity_at_dc: number
  inventory_quantity_at_store: number
  inventory_day_at_store: number
  no_of_new_customer: number
  no_of_return_customer: number
  no_of_bad_inventory: number
}

export const INVENTORY_BY_PROVINCE_COLUMNS: ColumnsType<InventoryByProvinceDataType> = [
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    width: 140,
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    width: 68,
    render: (image: string) => <img src={image} width={'100%'} height={'100%'} />,
  },
  {
    title: 'Storage',
    dataIndex: 'storage',
    key: 'storage',
    width: 264,
    render: (value: string[]) =>
      value && (
        <div>
          {value?.length <= 1 ? (
            value?.map((item, index) => {
              return (
                <div>
                  <span>
                    <span>{item}</span>
                  </span>
                </div>
              )
            })
          ) : (
            <div>
              <span>
                <span>{value[0]}</span>
              </span>
              <Popover
                content={
                  <span className="overflow-scroll" style={{ maxHeight: 552 }}>
                    {value?.map((item, index) => {
                      if (index >= 1) return <div>{item}</div>
                    })}
                  </span>
                }
              >
                <span className="ml-1" style={{ color: '#1890FF' }}>{` + ${value?.length - 1
                  } other stores`}</span>
              </Popover>
            </div>
          )}
        </div>
      ),
  },
  {
    title: 'Metrics',
    dataIndex: 'metrics',
    key: 'metrics',
    children: [
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        width: 80,
        render: (value: number) => <div className="font-semibold">{thousandsSeparator(value)}đ</div>,
      },
      {
        title: 'Inventory Status',
        dataIndex: 'inventory_status',
        key: 'inventory_status',
        width: 128,
        render: (status: string) => (
          <div>
            <StatusBadge status={status}></StatusBadge>
          </div>
        ),
      },
      {
        title: 'Inventory Quantity',
        dataIndex: 'inventory_quantity',
        key: 'inventory_quantity',
        width: 140,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Inventory Day',
        dataIndex: 'inventory_day',
        key: 'inventory_day',
        width: 113,
        render: (value: number) => <div>{value?.toFixed(0)}</div>,
      },
      {
        title: 'Inventory Value',
        dataIndex: 'inventory_value',
        key: 'inventory_value',
        width: 123,
        render: (value: number) => <div>{kFormatter(value)}</div>,
      },

      {
        title: 'Avg Revenue/day (last 7 days)',
        dataIndex: 'avg_revenue_per_day',
        key: 'avg_revenue_per_day',
        width: 202,
        render: (value: number) => <div>{kFormatter(value)}</div>,
      },
      {
        title: 'Inventory Quantity at DC',
        dataIndex: 'inventory_quantity_at_dc',
        key: 'inventory_quantity_at_dc',
        width: 172,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Inventory Day at DC',
        dataIndex: 'inventory_day_at_dc',
        key: 'inventory_clearance_at_dc',
        width: 148,
        render: (value: number) => <div>{value?.toFixed(0)}</div>,
      },
      {
        title: 'Inventory Quantity at Store',
        dataIndex: 'inventory_quantity_at_store',
        key: 'inventory_quantity_at_store',
        width: 189,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'Inventory Day at Store',
        dataIndex: 'inventory_day_at_store',
        key: 'inventory_quantity_at_store',
        width: 161,
        render: (value: number) => <div>{value?.toFixed(0)}</div>,
      },
      // {
      //   title: 'No. of New Customer',
      //   dataIndex: 'no_of_new_customer',
      //   key: 'no_of_new_customer',
      //   width: 156,
      //   render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      // },
      {
        title: 'No. of Return Customer',
        dataIndex: 'no_of_return_customer',
        key: 'return_customer',
        width: 169,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
      {
        title: 'No. of Bad Inventory',
        dataIndex: 'no_of_bad_inventory',
        key: 'bad_inventory',
        width: 169,
        render: (value: number) => <div>{thousandsSeparator(value)}</div>,
      },
    ],
  },
]
